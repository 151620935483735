thead {
  background: aliceblue;
}

#new_team {
  margin-bottom: 10px;
}

#teams__order {
  width: 5px;
}

#teams__active {
  width: 50px;  /* Increase to provide space */
  text-align: center;
}

.centered-cell {
  text-align: center;
  vertical-align: middle;
}

#teams__members {
  width: 15px;
}

#teams__wbs {
  width: 15px;
}

#teams__delete {
  width: 20px;
}

.teams__tr {
  text-align: start;
}

/* Targets 2nd column in team rows for better alignment and readability */
.teams__tr td:nth-child(2) {
  /* text-align: center; */
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
}

.teams__order--input div {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}



.teams__overview--top {
  background: transparent;
}


.teams__overview--top .card {
  width: 200px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

#card_team {
  background: #1d62f0;
  color: white;
}

#card_active,
.card#card_non_active {
  background: #fbad4c;
  color: white;
}

.teams__order--input div {
  margin-top: 7px;
}

.teams__active--input i {
  margin-top: 10px;
  cursor: pointer;
}

.teams__name--input input {
  border: 1px solid white;
}

.teams__name--input input:hover {
  border: 1px solid #dee2e6;
}

.isActive {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.isActive i {
  color: lawngreen;
  margin-top: 0px;
}

.isNotActive i {
  color: #dee2e6;
  margin-top: 0px;

}

.isNotActive {
  color: #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.isDisabled {
  opacity: 0;
  transition: opacity 10ms;
  display: none;
}

tr:hover {
  background: #e9f6ff;
}

tr.dark-mode:hover {
  background: #2f4157;
}

.user-auto-cpmplete {
  cursor: pointer;
  padding: 5px;
}

.user-auto-cpmplete:hover {
  background: #1d62f0;
}

.usermanagement-actions-cell {
  height: 35px; /* Set a proper height */
  padding: 5px 10px;
  font-size: 14px;
  line-height: normal;
}

@media (max-width: 768px) {
  .overflow-container {
    overflow-x: auto;
    overflow-y: auto;
    height: 60vh;
  }
}

.overflow-container table {
  min-width: 600px;
}